<script lang="ts">
  import { ChevronDown } from 'lucide-svelte';
  import * as DropdownMenu from '$lib/components/ui/dropdown-menu/index.js';

  import { saveContact } from '$lib/api/mutations';
  import { Pulse } from 'svelte-loading-spinners';

  import Button from '../components/ui/button/button.svelte';
  import { toast } from 'svelte-sonner';
  import { appStore } from '$lib/app-store';
  import AlreadyThere from './already-there.svelte';
  let contact = null;
  let loading: boolean = false;
  const onLinkContact = async e => {
    if (!$appStore.linkedInContact) return;
    console.log('onLinkContact', e.detail, $appStore.linkedInContact);
    const contactToSave = {
      ...e.detail,
      linkedin: $appStore.linkedInContact.linkedin,
      name: $appStore.linkedInContact.name,
      firstname: $appStore.linkedInContact.firstname,
      lastname: $appStore.linkedInContact.lastname,
      photo: $appStore.linkedInContact.photo,
      linkedInPhoto: $appStore.linkedInContact.linkedInPhoto,
      focus: $appStore.linkedInContact.focus,
      candidateCompany: $appStore.linkedInContact.candidateCompany,
      resume: $appStore.linkedInContact.resume,
      resumeLinkedIn: $appStore.linkedInContact.resumeLinkedIn,
      position: $appStore.linkedInContact.position,
      contact: $appStore.linkedInContact.contact,
    };

    await saveContactFn(contactToSave);
  };

  const onSaveContact = async (role: string) => {
    const contactToSave = {
      ...$appStore.linkedInContact,
      role: role,
    };
    await saveContactFn(contactToSave);
  };

  const saveContactFn = async contactToSave => {
    loading = true;
    try {
      const newContact = await saveContact(contactToSave);
      appStore.setCrmContact(newContact);
      toast.info('Contact saved!');
    } catch (e) {
      toast.error(
        'Error occurred: ' + e?.message ||
          'Unknown error occurred. Please try again later.',
      );
    } finally {
      loading = false;
    }
  };
</script>

{#if $appStore.linkedInContact?.name && $appStore.linkedInContact?.firstname && $appStore.linkedInContact?.lastname}
  <div class="crm-container">
    <div class="mt-2 crm-header">
      <!--Avatar photo={contact?.photo} /-->

      <p class="mt-2">
        <span class="name"
          >{$appStore.linkedInContact?.firstname ?? ''}
          {$appStore.linkedInContact?.lastname ?? ''}
        </span>
        is not in your CRM yet
      </p>
    </div>

    <div class="flex mt-6">
      <div class="mr-10">
        <AlreadyThere
          module="contacts"
          bind:item={contact}
          on:link={onLinkContact}
          disabled={loading}
        />
      </div>

      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild let:builder>
          <Button
            builders={[builder]}
            variant="default"
            disabled={loading}
            class="w-128"
            >Add to CRM
            <ChevronDown class="w-4 h-4 ml-2" />
            {#if loading}
              <div class="ml-2">
                <Pulse size="15" color="#5B6174" unit="px" duration="1.5s" />
              </div>
            {/if}
          </Button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content class="w-128">
          <DropdownMenu.Item on:click={() => onSaveContact('Contact')}
            >as Contact</DropdownMenu.Item
          >
          <DropdownMenu.Item on:click={() => onSaveContact('Candidate')}
            >as Candidate</DropdownMenu.Item
          >
          <DropdownMenu.Item
            on:click={() => onSaveContact('ContactAndCandidate')}
            >as Contact and Candidate</DropdownMenu.Item
          >
          <DropdownMenu.Item on:click={() => onSaveContact('Employee')}
            >as Employee</DropdownMenu.Item
          >
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  </div>
{/if}

<style>
  .name {
    font-weight: bold;
  }
  .crm-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  .crm-header {
    text-align: left;
  }
</style>
